'use client'

import { createContext, useContext, useState, useEffect, useTransition, useLayoutEffect, useRef, memo, Suspense, use } from 'react'
import { type Params } from './actions'
import type { documentTypeFilters, sortOptionMapppings } from '@/lib/search'
import type { DocumentSearchResponse } from '@/lib/search.server'
import { createStore, useStore, type StoreApi } from 'zustand'
import type { ParserLink } from '@gesetzefinden-at/core'
import { useSearchParams } from 'next/navigation'
import useFirstRender from '@/hooks/useFirstRender'
import { isDev, nonNullable } from '@/lib/utils'
import { defaultParams } from './params'
import { sendSlackMessageFrontendError } from '@/lib/sendSlackMessage'

export type IRichResult = ParserLink & { description?: string } & Record<string, any>
interface DateRange {
  from?: string
  to?: string
}
export interface ISearchFilters {
  documentType?: keyof typeof documentTypeFilters | Array<keyof typeof documentTypeFilters>
  institution?: string | string[]
  lawType?: string | string[]
  legalCategories?: string | string[]
  inEffectDate?: DateRange
  outOfEffectDate?: DateRange
  publishedDate?: DateRange
  decisionDate?: DateRange
}

type ISearchResults = DocumentSearchResponse & {
  richResult?: IRichResult
}

export interface SearchContextStore {
  query: string
  results?: ISearchResults | Promise<ISearchResults>
  error?: Error
  isPending?: boolean
  filter?: ISearchFilters | null
  sortBy?: Array<keyof typeof sortOptionMapppings>
  page?: number

  pending?: {
    query?: SearchContextStore['query']
    filter?: SearchContextStore['filter']
    sortBy?: SearchContextStore['sortBy']
    page?: SearchContextStore['page']
  }
  setSortBy: (sortBy: Array<keyof typeof sortOptionMapppings> | undefined) => void
  setQuery: (query: string) => void
  setFilter: (filter: ISearchFilters | null) => void
  setPage: (page: number) => void
}

interface SearchContextValue {
  store: StoreApi<SearchContextStore>
  params: Params
  id?: string
}

const SearchContext = createContext<SearchContextValue | null>(null)

export interface SearchProviderProps {
  id?: string
  children: React.ReactNode
  /** Controlled query input. Overrides the provided `params.q` */
  query?: string
  /** Controlled sortBy input. Overrides the provided `params.sortBy` */
  sortBy?: Array<keyof typeof sortOptionMapppings>
  page?: number
  results?: SearchContextStore['results']
  params?: Params
  filter?: ISearchFilters
  indexName?: 'documents' | 'query_suggestions' | 'citations'
  richSearch?: boolean
  url?: boolean
  mode?: 'auto' | 'text'
  minCharacters?: number
}

export default function SearchProvider ({ children, params: _params = {}, minCharacters = 1, filter = {}, indexName, id, richSearch, results, url = false, query, sortBy = ['decisionDate'], mode }: SearchProviderProps) {
  const [store] = useState(() => {
    return createStore<SearchContextStore>()((set) => {
      return {
        query: _params.q ?? '',
        results,
        sortBy,
        setQuery (query) { set({ query }) },
        setFilter (filter) { set({ filter: filter === null ? {} : filter }) },
        setSortBy (sortBy) { set({ sortBy }) },
        setPage (page) { set({ page }) },
      }
    })
  })

  const ref = useRef(_params)
  const paramsChanged = JSON.stringify(_params) !== JSON.stringify(ref.current)
  ref.current = paramsChanged ? _params : ref.current

  const value = { store, filter, results, params: ref.current, id, richSearch, url, query, mode, minCharacters }

  return (
    <SearchContext.Provider value={value}>
      {/*
        * Keep data fetching logic in separate child component as to not trigger re-renders for all children.
        * The `useEffect()` call stays in the TypesenseRequest component.
        */}
      <Suspense>
        <TypesenseRequests {...value} indexName={indexName} />
      </Suspense>
      {children}
    </SearchContext.Provider>
  )
}

/**
 * Handles requests to the typesense API and updates the search provider store.
 */
const TypesenseRequests = memo(function TypesenseRequest ({ store, params, minCharacters = 1, filter: defaultFilter, indexName, id = 'unknown', richSearch, url, query: controlledQuery, mode }: Omit<SearchProviderProps & { store: StoreApi<SearchContextStore>, filter: ISearchFilters }, 'children'>) {
  const query = useStore(store, (state) => state.query) || params?.q
  const filter = useStore(store, (state) => state.filter)
  const sortBy = useStore(store, (state) => state.sortBy)
  const page = useStore(store, (state) => state.page)

  const [isPending, startTransition] = useTransition()
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const [results, setResults] = useState<SearchContextStore['results']>(() => {
    return store.getState().results
  })
  const [error, setError] = useState<Error | undefined>()

  const isFirstRender = useFirstRender()

  const [debouncedParams, setDebouncedParams] = useState({})
  useEffect(() => {
    if (isFirstRender) {
      console.debug(`[ID=${id}] skipping because of first render`)
      return
    }
    console.log(`[ID=${id}][live] params update`, { defaultFilter, filter, sortBy, page, query, params })
    const timeout = setTimeout(() => {
      console.log(`[ID=${id}][debounced] params update`)
      setDebouncedParams({})
    }, 300)
    return () => { clearTimeout(timeout) }
  }, [defaultFilter, filter, sortBy, page, query, params])

  useEffect(() => {
    controlledQuery && store.getState().setQuery(controlledQuery)
  }, [controlledQuery])

  useEffect(() => {
    if (isFirstRender && results) return // If the request was server rendered, don't re-fetch on initial load
    if (query && query.length <= minCharacters) return // Don't fetch if query is too short

    startTransition(async () => {
      try {
        const combinedFilters = { ...defaultFilter, ...removeUndefined({ ...filter }) }
        const _filter = Object.entries(combinedFilters)
          .filter(([, value]) => value !== undefined)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return `(${value.map((item) => `${key}:${item as string}`).join(' || ')})`
            }
            if (typeof value === 'object') {
              return [value.from ? `${key}:>${new Date(value.from).getTime()}` : undefined, value.to ? `${key}:<${new Date(value.to).getTime()}` : undefined].filter(nonNullable)
            }
            return `${key}:${value}`
          })
          .flat()

        // FIXME add sane error handling
        const response = await fetch('/api/search', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: id + '[client]',
            indexName,
            page,
            richSearch,
            mode,
            ...defaultParams,
            ...params,
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            q: query,
            sort_by: (sortBy && sortBy.length > 0) ? `_text_match:desc,${sortBy.map((sort) => `${sort}:desc`).join(',')}` : undefined,
            filter_by: [..._filter, ...[params?.filter_by].flat()],
          }),
        })

        setResults(await response.json())
      } catch (error) {
        console.error(`[ID=${id}] error fetching search results`, error, debouncedParams)
        sendSlackMessageFrontendError(`${isDev ? '[DEV]' : ''} Error making search query: ${error.message as string}\n\nParams: ${JSON.stringify(debouncedParams, null, 2)}`)
          .catch((error) => { console.error('Failed to send message:', error) })
        setError(error)
      }
    })
  }, [debouncedParams])

  useLayoutEffect(() => {
    // isPending check to make sure we only set results for the last request
    store.setState(({ results: _results }) => ({ isPending, results: isPending ? _results : results, error }))
  }, [isPending, results, error])

  const searchParams = useSearchParams()
  const q = searchParams?.get('q')
  useEffect(function syncUrlToState () {
    url && store.setState({ query: q ?? '' })
  }, [url, q])

  return null
})

export function useSearch<T = SearchContextStore> (selector?: (state: SearchContextStore) => T) {
  const { store } = useContext(SearchContext) ?? {}
  if (!store) throw new Error('useSearch must be used within a SearchProvider')

  // @ts-expect-error Don't know why it doesn't want the selector type...
  return useStore(store, selector)
}

function removeUndefined (data: Record<string, any>) {
  const cleaned = Object.entries(data)
    .filter(([key, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      obj[key] = value
      return obj
    }, {})
  return cleaned
}

// eslint-disable-next-line @typescript-eslint/promise-function-async
export function useSearchResults (): ISearchResults {
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const results = useSearch((state) => state.results)
  /**
   * `results` can either be a promise or the actual results object.
   * In the initial response, the promise is streamed to the client and a fallback is shown via Suspense.
   * By streaming the initial search response, the rest of the page can already be shown the the user.
   */
  // @ts-expect-error Checking `.then()` is the only way to reliably detect Promises, see https://stackoverflow.com/a/27746324
  return results?.then
    // @ts-expect-error TypeScript doesn't infer that results is only a promise in this case
    ? use(results)
    : results
}
